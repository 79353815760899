import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialToolsOfferWall } from '../components/FinancialToolsOfferWall/FinancialToolsOfferWall';
import { Hero } from '../components/InnerPages/Hero';
import CoursesIcon from '../images/Courses';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface ICoursesProps {
  location: any;
}

const Course = ({ location }: ICoursesProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "153398";
        _clcktt['cIdMobile'] = "89271";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Learn how to have Financial Freedom" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<CoursesIcon />}
          title="Learn how to have Financial Freedom"
          description="Courses that make financial literacy simple and accessible."
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
            <FinancialToolsOfferWall
              items={[
                {
                  title: 'Personal Finance Club Review',
                  link: '/jeremy-personal-finance-club/',
                },
                {
                  title: 'Learn More',
                  link: '/tag/investing/',
                },
                {
                  title: 'Personal Finance Club',
                  link: 'https://courses.personalfinanceclub.com/?ref=d35b6e',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Course;
